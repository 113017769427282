<template>
  <div>
    <div class="top-bar flex flex-col justify-between">
      <div class="nav-bar flex justify-between sm:justify-start">
        <div
          :class="['bar-list', { is_active: currentTab === 'Account' }]"
          v-on:click="()=> {handleChangeTab('Account')}"
        >
          Account
        </div>
         <!-- <div
          :class="['bar-list', { is_active: currentTab === 'Security' }]"
          v-on:click="()=> {handleChangeTab('Security')}"
        >
          多币种账户
        </div> -->
      </div>
    </div>
    <div class="bottom">
      <transition mode="out-in" :name="transition_name">
        <component :cc_btn_open_disabled="cc_btn_open_disabled" v-bind:is="currentTabComponent" ></component>
      </transition>
    </div>
  </div>
</template>

<script>
import ProfileAccount from '@/components/User/ProfileAccount.vue'
import ProfileDash from '@/components/User/ProfileDash.vue'
import { mapState } from 'vuex'

export default {
  props: {
    cc_btn_open_disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChangeTab (name) {
      const fromIndex = this.tabs.indexOf(this.currentTab)
      const component = name
      const toIndex = this.tabs.indexOf(component)
      if (toIndex < fromIndex) {
        this.transition_name = 'swipe-right'
      } else {
        this.transition_name = 'swipe-left'
      }
      this.currentTab = component
    }
  },
  components: {
    Account: ProfileAccount,
    Security: ProfileDash
  },
  data () {
    return {
      tabs: ['Account', 'Security'],
      currentTab: 'Account',
      transition_name: 'swipe-left',
      new_tabs: [
        {
          key: 1,
          name: 'Account',
          title: 'Account'
        },
        {
          key: 2,
          name: 'Security',
          title: '多币种账户'
        }
      ]
    }
  },
  computed: {
    currentTabComponent () {
      return this.currentTab
    },
    ...mapState('login', ['status', 'attributes'])
  }
}
</script>

<style scoped>
.marginRL60 {
  margin: 0 60px;
}

.is_active {
  border-bottom: 3px solid #bc9a41;
}

.swipe-left-enter {
  opacity: 0;
  transform: translateX(50%);
}
.swipe-left-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}
.swipe-left-enter-active,
.swipe-left-leave-active {
  transition: all 0.3s ease;
}
.swipe-right-enter {
  opacity: 0;
  transform: translateX(-50%);
}
.swipe-right-leave-to {
  opacity: 0;
  transform: translateX(50%);
}
.swipe-right-enter-active,
.swipe-right-leave-active {
  transition: all 0.3s ease;
}
@media only screen and (max-width: 600px) {
  .marginRL60 {
    margin: 0 60px;
  }
  .bottom {
    padding: 5vw;
    display: flex;
  }
  .is_active {
    border-bottom: 3px solid #bc9a41;
    color: #163257;
  }
}
</style>
