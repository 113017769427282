<template>
  <div class="profile-account-container">
    <div class="profile-account-left">
      <div class="profile-address">
        <div class=" text-xl font-500">账户验证/User Verification</div>
          <div class="flexBetween alignCenter  black45 mt-4">
              <div class="flex items-center">
                电话验证/Phone Verification:
              </div>
              <div class=" text-14px">
              <a-tag color="green">Authenticated</a-tag>
              <!-- <span class="green">正在审核</span> -->
              </div>
            </div>
            <div class="flexBetween alignCenter marginTop20 black45">
              <div class="flex items-center">
                开户认证/Account Verification:
              </div>
              <div class=" text-14px">
                <a-tag v-if="status.compliance_acitve" color="green">Authenticated</a-tag>
                <a-tooltip v-else placement="topLeft" title="请完成开户认证" arrow-point-at-center>
                  <a-tag color="red">Unverified</a-tag>
                </a-tooltip>
              </div>
            </div>
            <div class="flexBetween alignCenter marginTop20 black45">
              <div class="flex items-center">
                开户类型/Account Type:
              </div>
              <div class=" text-14px">
                <a-tag v-if="status.login_type_str">
                  {{status.login_type_str}}
                </a-tag>
                <div v-else></div>
              </div>
            </div>
            <div class="flexBetween alignCenter mt-4 open_link flex-row-reverse">
              <a-button @click="RouteToForm" class=" font-500 tracking-wide" >VERTIFY/开户认证 <a-icon type="right-circle" /></a-button>
            </div>
      </div>
      <div v-show="status.basic_info_completed" class="profile-person marginTop20">
        <div class="account-title text-xl">
          个人信息</div>
        <div class=" grid grid-cols-2 grid-rows-2">
          <div class="account-text2 marginTop32">用户ID</div>
          <div class=" pl-8vw sm:pl-16 account-content marginTop32 weight600 fontBlack85 text-left">
              <!-- {{ user.mist.firstname + ' ' + user.mist.lastname}} -->
              {{attributes.username}}
            </div>
          <div class="account-text2 marginTop32">电话</div>
          <div class=" pl-8vw sm:pl-16 account-content marginTop32 text-left">
            {{attributes.mobilephone}}
            </div>
        </div>
      </div>
    </div>
    <div class="">
      <AccountSilverModal></AccountSilverModal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AccountSilverModal from './AccountSilverModal.vue'
export default {

  components: {
    AccountSilverModal
  },
  data () {
    return {
      test: false
    }
  },
  props: {
  },
  methods: {
    ...mapActions('login', ['setUserDetailProfileAction']),
    RouteToForm () {
      const { login_type: loginType } = this.status
      if (loginType) {
        if (loginType === 'I') {
          this.$router.push({ name: 'PersonApplicationOne' })
        } else if (loginType === 'E') {
          this.$router.push({ name: 'EntityApplicationOne' })
        }
      } else {
        this.$message.error('请重新登入')
      }
    }
  },
  computed: {
    ...mapState('login', ['status', 'attributes'])
  }
}

</script>

<style scoped>
.open_link{
  color: #bc9a41;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 500;
}
.profile-account-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.profile-account-right {
  width: 540px;
  flex-shrink: 0;
  margin-left: 16px;
}

.profile-person {
  background: #ffffff;
  padding: 2rem;
  border-radius: 4px;
  min-width: 420px;
}
.profile-email {
  background: #ffffff;
  padding: 2rem;
  border-radius: 4px;
}
.profile-phone {
  margin-top: 16px;
  padding: 2rem;
  border-radius: 4px;
  background: #ffffff;
}
.profile-address {
  border-radius: 4px;
  margin-top: 14px;
  padding: 2rem;
  background: #ffffff;
}
.flex3column {
  display: flex;
  justify-content: space-between;
}
.goldBtn {
  text-decoration: underline;
  color: #bc9a41;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}
.pink{
  color: #FF4D4F;
}
.green{
  color:#67C23A
}
@media only screen and (max-width:600px){
.profile-account-container {
  display: block;
  width: 100%;
}
.marginTop32{
  margin-top: 2vh;
}
.profile-account-right {
  width: 100%;
  flex-shrink: 0;
  margin-left: 0;
}
.profile-account-left {
  width: 100%;
  flex-shrink: 0;
}
.profile-person {
  background: #ffffff;
  padding: 5vw;
  border-radius: 4px;
  min-width: auto;
}
.profile-email {
  background: #ffffff;
  padding: 0 5vw 5vw 5vw;
  border-radius: 4px;
}
.profile-phone {
  margin-top: 16px;
  padding: 5vw;
  border-radius: 4px;
  background: #ffffff;
}
.profile-address {
  border-radius: 4px;
  margin-top: 14px;
  padding: 5vw;
  width: 100%;
  background: #ffffff;
}
.flex3column {
  display: flex;
  justify-content: space-between;
}
.goldBtn {
  text-decoration: underline;
  color: #bc9a41;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.account-text2{
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 16px;
}
.account-content{
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 16px;
}
.font14{
  font-size: 12px;
}
}
</style>
