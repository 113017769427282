<template>
  <div>
  <div v-for="item in dataList" :key="item.name" class="flex py-6  space-x-4">
    <div class="flex flex-col items-center border-r border-gray-500 px-4">
      <img class="  w-16" :src="silverModal" alt="">
      <div class="text-base font-600 mt-4">{{ item.name }}</div>
    </div>
    <div class="flex flex-col px-6">
      <div class="text-xl font-600">即刻升级至{{item.level_name}}等级并获享:</div>
      <div class=" flex space-x-4">
        <div class="mt-4 space-y-4 flex  flex-col flex-shrink-0 pr-8">
          <div v-for="ele in item.desc_list" :key="ele.id" class="flex items-start ">
            <img class="w-6 flex-shrink-0 mr-2" :src="starImg" alt="">
            <div class="flex flex-col">
              <div class=" text-sm font-500">{{ ele.name }}</div>
              <div class=" text-xs">{{ ele.desc }}</div>
            </div>
          </div>
        </div>
        <img class=" w-48 object-scale-down " :src="item.bank_src" alt="">
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import silverModal from '@/assets/img/silver_medal.png'
import goldModal from '@/assets/img/gold_medal.png'
import starImg from '@/assets/img/star.png'
import blueBcJPG from '@/assets/svg/blue_bc.jpg'
import blackBcSVG from '@/assets/svg/black_bc.jpg'

// const dataList = [
//   { id: 1, name: '多币种账户', desc: '添加最多10个币种的账户' },
//   { id: 2, name: '提款', desc: '进行银行账户提款' },
//   { id: 3, name: '最高购物限额', desc: '最高每天$3,000' },
//   { id: 4, name: '资金转账', desc: '在全球各地收款和转账' }
// ]
const dataList = [
  {
    name: '白金卡',
    level_name: '白金',
    desc_list: [
      { id: 1, name: '多币种账户', desc: '添加最多10个币种的账户' },
      { id: 2, name: '提款', desc: '进行银行账户提款' },
      { id: 3, name: '最高购物限额', desc: '最高每天$3,000' },
      { id: 4, name: '资金转账', desc: '在全球各地收款和转账' }
    ],
    bank_src: blueBcJPG
  },
  {
    name: '钻石卡',
    level_name: '钻石',
    desc_list: [
      { id: 1, name: '多币种账户', desc: '添加最多10个币种的账户' },
      { id: 2, name: '提款', desc: '进行银行账户提款' },
      { id: 3, name: '最高购物限额', desc: '最高每天$5,000' },
      { id: 4, name: '资金转账', desc: '在全球各地收款和转账' }
    ],
    bank_src: blackBcSVG
  }
]
export default {
  data () {
    return {
      dataList,
      silverModal,
      goldModal,
      starImg
    }
  }
}
</script>

<style scoped>

</style>
